import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth'

  //
  //Service responsavel pelos metodos do Usuario
  //

@Injectable({
  providedIn: 'root'
})
export class FirebaseService {

  authState: any = null;

  static getInstance() {
    throw new Error('Method not implemented.');
  }

  isLoggedIn = false
  constructor(public firebaseAuth : AngularFireAuth) { }

  async signin(email: string, password : string){
    let resp
    try {
      let user = await this.firebaseAuth.signInWithEmailAndPassword(email,password);
      this.isLoggedIn = true;
      localStorage.setItem('user',JSON.stringify(user));
      resp = "Ok"
    } catch(err) {
      resp = err;
    }
    return resp
  }
  async signup(email: string, password : string){
    await this.firebaseAuth.createUserWithEmailAndPassword(email,password)
    .then(res=>{
        // console.log(res);

      this.isLoggedIn = true
      localStorage.setItem('user',JSON.stringify(res.user))

    })
  }

  async resetPassword(email){
    let resp;
    try {
      await this.firebaseAuth.sendPasswordResetEmail(email);
      resp = "Ok"
    } catch(err) {
      resp = err;
    }
  }

  logout(){
    this.firebaseAuth.signOut();
    localStorage.removeItem('user');
  }

  get currentUserId(): string {
    return (this.authState !== null) ? this.authState.uid : '';
  }

}
