import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";

@Injectable({
    providedIn: 'root'
  })
export class WrapperService {

  constructor() { }

  getUrl(path, parameters, type) {
    // return `http://localhost:3333/${path}?${parameters}`;
    if (environment.production) {
      return `https://bg4rblhy15.execute-api.us-east-1.amazonaws.com/default/wrapperHttp?hostname=${encodeURIComponent(`http://cicampotimbo.ddns.net:3333`)}&path=${encodeURIComponent(path)}&parameters=${encodeURIComponent(parameters)}&type=${type}`;
    } else if (!environment.production && environment.db === 'test') {
      return `https://bg4rblhy15.execute-api.us-east-1.amazonaws.com/default/wrapperHttp?hostname=${encodeURIComponent(`http://cicampotimbo.ddns.net:3344`)}&path=${encodeURIComponent(path)}&parameters=${encodeURIComponent(parameters)}&type=${type}`;
    } else {
      return `http://localhost:3344/${path}?${parameters}`;
    }
  }

}
