import { Injectable } from '@angular/core';
import {
    HttpInterceptor,
    HttpRequest,
    HttpHandler,
    HttpEvent
} from '@angular/common/http';

import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { Router } from '@angular/router';

@Injectable()
export class HttpConfigInterceptor implements HttpInterceptor {

    constructor(
        private router: Router
    ) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

        const user = localStorage.getItem('cicampo.user');
        
        if (user && request.url.indexOf('punnekmvqtxqiscaem3nbev5s40jmhqg') == -1) {
            request = request.clone({ headers: request.headers.set('x-user', user) });
        }

        return next.handle(request).pipe(
            catchError(error => {
                if (error.status === 403) {
                    localStorage.clear();
                    this.router.navigate(['/']);
                }
                return throwError(error);
            }),
            map((event: HttpEvent<any>) => {
                return event;
            }));
    }
}
