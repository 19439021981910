import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';
import { Router } from '@angular/router';
import { UserService } from "../services/user/user-service.service";

@Injectable()
export class AuthGuard implements CanActivate {
    constructor(
            private router: Router,
            private userService: UserService
            ) {}

    async canActivate() {
        let user = await this.userService.currentUser();
        if (user && localStorage.getItem('user')) {
            return true;
        }

        this.router.navigate(['/login']);
        return true;
    }
}
