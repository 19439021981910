import { TestesComponent } from './testes/testes.component';
import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { AuthGuard } from "./shared/guard";


const routes: Routes = [
    {
        path: "",
        canActivate: [AuthGuard],
        loadChildren: () => import("./layout/layout.module").then((m) => m.LayoutModule)
    },
    {
        path: "login",
        loadChildren: () => import("./no-auth-pages/login/login.module").then((m) => m.LoginModule)
    },
    {
        path: "login-network",
        loadChildren: () => import("./no-auth-pages/login-network/login.module").then((m) => m.LoginNetWorkModule)
    },
    {
        path: "outside",
        loadChildren: () => import("./no-auth-pages/edit-outside/edit.module").then((m) => m.EditModule)
    },
    {
        path: "error",
        loadChildren: () => import("./error-pages/server-error/server-error.module").then((m) => m.ServerErrorModule)
    },
    {
        path: "access-denied",
        loadChildren: () => import("./error-pages/access-denied/access-denied.module").then((m) => m.AccessDeniedModule)
    },
    {
        path: "not-found",
        loadChildren: () => import("./error-pages/not-found/not-found.module").then((m) => m.NotFoundModule)
    },
    {
        path: "testes",
        component: TestesComponent
    },
    { path: "**", redirectTo: "not-found" }
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule],
})
export class AppRoutingModule {}
