import { FirebaseService } from 'src/app/shared/services/user/firebase.service';
import { UserService } from 'src/app/shared/services/user/user-service.service';
import { Component, HostListener, OnInit } from '@angular/core';
import { inspect } from 'util'

@Component({
    selector: 'app-testes',
    templateUrl: './testes.component.html',
    styleUrls: ['./testes.component.css']
})
export class TestesComponent implements OnInit {

    user = {
        login: "",
        senha: ""
    }
    constructor(
        private firebaseService: FirebaseService
    ) { }

    ngOnInit(): void {
    }


    createUser() {
        alert('nao esta funcionando')
        // console.log(this.user);
        // this.firebaseService.signup(this.user.login, this.user.senha)
    }


}


