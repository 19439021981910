<p-toast [style]="{marginTop: '50px', 'z-index': '2000 !important'}" appendTo="body"></p-toast>

<router-outlet></router-outlet>

<p-dialog header="" [(visible)]="erroCaps" [modal]="true" [style]="{width: '50vw'}" [closeIcon]="false" [closable]="false"
    [draggable]="false" [resizable]="false">
    <h1 style="color: brown; text-align: center;"><b>ATENÇÃO!</b></h1>
    <p style="text-align: center;">Para continuar atualize a versão do App</p>
    <p style="text-align: center; color: rgb(23, 39, 184);">Versão correta: {{newVersion}}</p>
</p-dialog>
