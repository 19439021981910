import { CommonModule } from '@angular/common';
import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { FormsModule } from '@angular/forms';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

// Precisa ficar aqui pois é dialog
import { DropdownModule } from 'primeng/dropdown';
import { CheckboxModule } from 'primeng/checkbox';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { MessageService } from 'primeng/api';
import { ToastModule } from 'primeng/toast';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { AuthGuard } from './shared/guard';

import { UserService } from "./shared/services/user/user-service.service";
import { AngularFireModule } from '@angular/fire';
import { LOCALE_ID } from '@angular/core';
import { environment } from '../environments/environment';
import { TestesComponent } from './testes/testes.component';
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { HttpConfigInterceptor } from './core/interceptor';
import { DialogModule } from 'primeng/dialog';

// export const createTranslateLoader = (http: HttpClient) => {
//     return new TranslateHttpLoader(http, './assets/i18n/', '.json');
// };

@NgModule({
    imports: [
        CommonModule,
        BrowserModule,
        BrowserAnimationsModule,
        HttpClientModule,
        FormsModule,
        // TranslateModule.forRoot({
        //     loader: {
        //         provide: TranslateLoader,
        //         useFactory: createTranslateLoader,
        //         deps: [HttpClient]
        //     }
        // }),
        AppRoutingModule,
        DropdownModule,
        CheckboxModule,
        DialogModule,
        ToastModule,
        AutoCompleteModule,
        AngularFireAuthModule,
        AngularFirestoreModule,
        AngularFireModule.initializeApp(environment.firebase),
    ],
    declarations: [
        AppComponent,
        TestesComponent
    ],
    providers: [
        AuthGuard,
        MessageService,
        UserService,
        {
            provide: LOCALE_ID,
            useValue: "pt-BR"
        },
        { provide: HTTP_INTERCEPTORS, useClass: HttpConfigInterceptor, multi: true }
    ],
    bootstrap: [AppComponent]
})
export class AppModule { }
