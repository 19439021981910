export const environment = {
  production: true,
  db: "prod",
  name: 'prod',
  firebase: {
    apiKey: "AIzaSyCGsnW6GD2YQxo2yG2ccR0DXSp4DQojKKI",
    authDomain: "portal-cicampo.firebaseapp.com",
    projectId: "portal-cicampo",
    storageBucket: "portal-cicampo.appspot.com",
    messagingSenderId: "487453106726",
    appId: "1:487453106726:web:c520ef23f94b30ec3dbce7",
    measurementId: "G-M7HGMW0GPL"
  }
};
